'use client';
import Link from 'next/link';
import React from 'react';

import Icon from '@/components/v3/Icon';
import { If } from '@/components/UtilitiesComponents';
import { InboundV2InfoShellProps } from '../types';

const stoneBenefitsList = [
  {
    description: 'Entregas e trocas em 1 dia útil',
  },
  { description: 'Pix direto na Maquininha' },
  { description: 'Recebimento em 1 dia útil' },
  { description: 'Conta Stone grátis' },
];

const InboundV2MobileInfo: React.FC<InboundV2InfoShellProps> = ({
  onPhoneClick,
  children,
  imageCampaignComponent,
  shouldShowImageCampaignComponent = false,
}) => {
  return (
    <>
      <div className="flex relative flex-col md:justify-center md:pt-0 w-full min-h-screen">
        <div className="flex md:hidden absolute inset-x-0 top-0 justify-center">
          <div className="flex justify-between items-center py-10 px-24 mb-24 w-full border-b border-display-200 border-solid">
            <Link href="/" aria-label="Link para página inicial">
              <Icon
                name="logo/stone"
                className="w-[92px] h-[24px] fill-current"
              />
            </Link>
            <div className="flex flex-col">
              <div>
                <Link
                  href="tel:30049681"
                  className="py-12 px-16 btn btn-primary btn-regular"
                  onClick={onPhoneClick}
                >
                  <p className="flex text-white paragraph-16">
                    <Icon
                      name="phone"
                      className="mr-8 text-white fill-current"
                    />
                    Ligue
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="md:hidden">
          <div className="flex relative flex-col justify-center items-center md:p-0 mt-[65px] md:mt-0 w-full bg-display-200">
            <If condition={shouldShowImageCampaignComponent}>
              {imageCampaignComponent}
            </If>

            <div className="py-16 px-24">
              <div>
                <h4 className="font-display text-2xl font-bold">
                  Fale com o nosso time
                </h4>
                <p>Conheça esses e outros os benefícios de ser Stone</p>
                <div className="flex flex-col gap-8 mt-16">
                  {stoneBenefitsList.map(({ description }, index) => (
                    <div key={index} className="flex gap-10">
                      <Icon
                        name="check"
                        className="w-24 h-24 text-stone-600 fill-current"
                      />
                      <p>{description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-display-200">
            <div className="flex flex-col gap-16 justify-center p-24 md:p-0 md:mt-0 w-full h-full bg-white rounded-t-2xl">
              <h6 className="font-display font-bold">
                Informe abaixo os seus dados
              </h6>
              {children}
            </div>
          </div>
        </div>
        <div className="hidden md:flex flex-col gap-16 justify-center p-24 md:p-0 mt-[65px] md:mt-0 w-full h-full">
          {children}
        </div>
      </div>
    </>
  );
};
export default InboundV2MobileInfo;

import Link from 'next/link';
import { FC } from 'react';

import Icon from '@/components/v3/Icon';

const LogoStone: FC<{ colorLogo?: string }> = ({
  colorLogo = 'text-stone-500',
}) => {
  return (
    <Link href="/" prefetch={false} aria-label="Stone" className={colorLogo}>
      <Icon name="logo" className={`fill-current w-[92px] h-[24px]`} />
    </Link>
  );
};

export default LogoStone;

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  id: string;
  size?: number;
  color?: string;
  spritesheet?: string;
}

function Icon({
  id,
  strokeWidth = 16,
  size = 24,
  width,
  height,
  color,
  spritesheet = 'icons.svg',
  ...otherProps
}: IconProps) {
  return (
    <svg
      {...otherProps}
      width={width ?? size}
      height={height ?? size}
      strokeWidth={strokeWidth}
      className={color ? `text-${color}` : undefined}
    >
      <use href={`/${spritesheet}#${id}`} />
    </svg>
  );
}

export default Icon;

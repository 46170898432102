import { isAfter, isBefore, isEqual } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export const getCurrentTimeInTimezone = (timezone: string): Date => {
  const now = new Date();
  return toZonedTime(now, timezone);
};

const createHourDate = (baseDate: Date, hour: number): Date => {
  const hourDate = new Date(baseDate);
  hourDate.setHours(hour, 0, 0, 0);
  return hourDate;
};

const isTimeBetweenOrInclusiveRange = (
  currentTime: Date,
  startTime: Date,
  endTime: Date,
): boolean => {
  const isAfterOrEqualStartTime =
    isAfter(currentTime, startTime) || isEqual(currentTime, startTime);
  const isBeforeOrEqualEndTime =
    isBefore(currentTime, endTime) || isEqual(currentTime, endTime);

  return isAfterOrEqualStartTime && isBeforeOrEqualEndTime;
};

const isCurrentTimeBetweenChosenHours = (
  firstHour: number,
  lastHour: number,
  timezone = 'America/Sao_Paulo',
): boolean => {
  const currentTimeInTimezone = getCurrentTimeInTimezone(timezone);
  const startTime = createHourDate(currentTimeInTimezone, firstHour);
  const endTime = createHourDate(currentTimeInTimezone, lastHour);
  const isRangeCrossingMidnight = firstHour > lastHour;

  if (isRangeCrossingMidnight) {
    const isCurrentTimeAfterFirstHour =
      isAfter(currentTimeInTimezone, startTime) ||
      isEqual(currentTimeInTimezone, startTime);
    const isCurrentTimeBeforeLastHour =
      isBefore(currentTimeInTimezone, endTime) ||
      isEqual(currentTimeInTimezone, endTime);

    return isCurrentTimeAfterFirstHour || isCurrentTimeBeforeLastHour;
  }

  return isTimeBetweenOrInclusiveRange(
    currentTimeInTimezone,
    startTime,
    endTime,
  );
};

export default isCurrentTimeBetweenChosenHours;

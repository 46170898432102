'use client';

import React from 'react';

import Button from '@/components/Button';
import Icon from '@/components/v3/Icon';

type OutOfAttendanceModalProps = {
  onClose: () => void;
};

const OutOfAttendanceModal: React.FC<OutOfAttendanceModalProps> = ({
  onClose,
}) => {
  return (
    <div className="flex relative flex-col justify-center items-center pt-80 sm:pt-0 max-w-[448px] h-full sm:h-auto text-center">
      <button
        onClick={onClose}
        className="absolute top-0 sm:top-0 right-0 sm:-right-40 p-8 sm:mt-[-8px] ml-auto w-40 h-40 bg-transparent rounded-full"
      >
        <Icon name="close" className="text-display-900 fill-current" />
      </button>
      <div className="flex justify-center w-full">
        <Icon name="animation/whatsapp" className="w-80 h-80" />
      </div>
      <h5 className="mt-16 font-bold heading-3">
        Estamos fora do horário de atendimento
      </h5>

      <p className="mt-8 paragraph-16">
        Entraremos em contato com você assim que começarmos a atender.
      </p>

      <div className="flex flex-col gap-8 mt-16 w-full">
        <p className="font-semibold text-center text-display-900 paragraph-16">
          Horário de atendimento:
        </p>
        <ul className="flex flex-col gap-8 items-center">
          <li className="flex gap-8 items-center text-center">
            <Icon
              name="arrow-fill-left"
              className="text-display-900 fill-current"
            />
            Segunda a Sexta das 6h às 00h
          </li>
          <li className="flex gap-8 items-center text-center">
            <Icon
              name="arrow-fill-left"
              className="text-display-900 fill-current"
            />
            Finais de semana e feriados das 7h às 19h
          </li>
        </ul>
      </div>

      <Button
        id="button-redirect-home"
        color="secondary"
        onClick={onClose}
        className="mt-auto sm:mt-24 w-full"
      >
        Voltar para o site
      </Button>
    </div>
  );
};

export default OutOfAttendanceModal;

'use client';

import { checkPathAccess } from '@/services/normalizedPath';
import InboundV2Info from './components/InboundV2InfoShell/InboundV2Info';
import InboundV2MobileInfo from './components/InboundV2InfoShell/InboundV2MobileInfo';
import { useCallback, useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import {
  BlackFridayImage,
  BlackFridayMobileImage,
} from './components/CampaignComponents/BlackFriday';
import { handlePhoneOnInboundSiteClick } from '@/utils/Analytics';

const InboundV2Container: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const pathname = usePathname() as string;
  const isBlackFriday = process.env.IS_BLACK_FRIDAY == 'true';
  const [isInboundNormalizedPath, setInboundNormalizedPath] = useState(false);
  const shouldShowCampaignImages = isInboundNormalizedPath && isBlackFriday;

  const onPhoneClick = useCallback(() => {
    handlePhoneOnInboundSiteClick('/adquira/');
  }, []);

  useEffect(() => {
    if (pathname) {
      setInboundNormalizedPath(
        checkPathAccess({ pathname, pathRestrictions: '/adquira' }),
      );
    }
  }, [pathname]);

  return (
    <>
      <InboundV2Info
        onPhoneClick={onPhoneClick}
        shouldShowImageCampaignComponent={shouldShowCampaignImages}
        imageCampaignComponent={<BlackFridayImage />}
      >
        <InboundV2MobileInfo
          onPhoneClick={onPhoneClick}
          shouldShowImageCampaignComponent={shouldShowCampaignImages}
          imageCampaignComponent={<BlackFridayMobileImage />}
        >
          {children}
        </InboundV2MobileInfo>
      </InboundV2Info>
    </>
  );
};

export default InboundV2Container;

import { isValidCnpj } from '@/helpers/validateDocument';
import * as yup from 'yup';

const typeString = yup.string();
const fieldRequired = typeString.required('Este campo é obrigatório!');

const mgmDocumentValidationCase = (isMgm?: boolean) => {
  if (isMgm) {
    return fieldRequired.test({
      name: 'isCnpjValid',
      skipAbsent: true,
      test(value, ctx) {
        if (!isValidCnpj(value)) {
          return ctx.createError({
            message: 'Informe um CNPJ válido',
          });
        }
        return true;
      },
    });
  }
  return typeString;
};

export const schema = (isMgm?: boolean) =>
  yup.object({
    name: fieldRequired.matches(
      /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/,
      'É necessário um nome válido!',
    ),
    email: fieldRequired.email('É necessário um e-mail válido!'),
    phone: fieldRequired.matches(
      /\(\d{2,}\) \d{4,}-\d{4}/g,
      'É necessário um telefone válido!',
    ),
    document: mgmDocumentValidationCase(isMgm),
    tpv: fieldRequired,
    channel: fieldRequired,
  });

import Link from 'next/link';

import StoneLogo from '@/assets/icons/stoneLogo/stone-logo.svg';

import Disclaimer from '../Footer/components/Disclaimer';

const ContentFooterWithoutRoutes: React.FC = () => {
  return (
    <>
      <div
        id="Context_footer"
        className="py-[32px] px-[24px] text-display-0 bg-display-900"
      >
        <div className="my-0 mx-auto max-w-[1324px]">
          <div className="flex flex-col md:flex-row my-0 mx-auto md:mt-[8px] md:h-auto">
            <Link
              href="/"
              prefetch={false}
              passHref
              className="flex flex-1 md:mb-0"
              aria-label="Link home"
            >
              <StoneLogo className="w-[96px] md:w-[106px] h-24 md:h-[28px]" />
            </Link>
          </div>
          <Disclaimer />
        </div>
      </div>
    </>
  );
};

export default ContentFooterWithoutRoutes;

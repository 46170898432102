'use client';

import { useRouter } from 'next/navigation';
import React, { useCallback } from 'react';

import { Choose } from '@/components/UtilitiesComponents';
import OutOfAttendanceModal from '../../containers/InboundV2DefaultFormsContainer/out-of-attendance-modal';
import AvailableModal from '../../containers/InboundV2DefaultFormsContainer/available-modal';

type TInboundV2SubmitModal = {
  showModal?: boolean;
  isOutOfAttendanceTime: boolean;
  onBackToSite?: () => void;
};

const InboundV2SubmitModal: React.FC<TInboundV2SubmitModal> = ({
  showModal = true,
  isOutOfAttendanceTime,
  onBackToSite,
}) => {
  const router = useRouter();

  const handleClose = useCallback(() => {
    if (onBackToSite) {
      return onBackToSite();
    }

    if (document.referrer.split('/')[2] !== window.location.hostname) {
      return router.push('/');
    }

    return router.back();
  }, [router, onBackToSite]);

  return showModal ? (
    <div className="flex fixed top-0 left-0 z-20 justify-center items-center w-full h-full bg-display-900 bg-opacity-75">
      <div className="flex flex-col justify-center items-center p-24 sm:py-40 sm:px-80 w-full sm:w-auto h-full sm:h-auto bg-display-0 sm:rounded-md">
        <Choose>
          <Choose.When condition={isOutOfAttendanceTime}>
            <OutOfAttendanceModal onClose={handleClose} />
          </Choose.When>
          <Choose.Otherwise>
            <AvailableModal onClose={handleClose} />
          </Choose.Otherwise>
        </Choose>
      </div>
    </div>
  ) : null;
};

export default InboundV2SubmitModal;

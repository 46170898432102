'use client';

import React from 'react';

import Button from '@/components/Button';
import Icon from '@/components/v3/Icon';

type AvailableModalProps = {
  onClose: () => void;
};

const AvailableModal: React.FC<AvailableModalProps> = ({ onClose }) => {
  return (
    <div className="flex relative flex-col justify-center items-center pt-80 sm:pt-0 max-w-[448px] h-full sm:h-auto text-center">
      <button
        onClick={onClose}
        className="absolute top-0 sm:top-0 right-0 sm:-right-40 p-8 sm:mt-[-8px] ml-auto w-40 h-40 bg-transparent rounded-full"
      >
        <Icon name="close" className="text-display-900 fill-current" />
      </button>
      <div className="flex justify-center w-full">
        <Icon name="animation/whatsapp" className="w-80 h-80" />
      </div>
      <h5 className="mt-16 font-bold heading-3">
        Você receberá uma mensagem agora no seu WhatsApp!
      </h5>

      <p className="mt-8 paragraph-16">
        Responda a nossa mensagem para que possamos conversar o quanto antes
        sobre o seu negócio.
      </p>

      <Button
        id="button-redirect-home"
        color="secondary"
        onClick={onClose}
        className="mt-auto sm:mt-24 w-full"
      >
        Voltar para o site
      </Button>
    </div>
  );
};

export default AvailableModal;

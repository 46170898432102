'use client';
import { Choose } from '@/components/UtilitiesComponents';
import Image from 'next/image';
import styles from './InboundInfo.module.css';

import { InboundV2InfoShellProps } from '../types';
import AcquireBenefits from './acquire-benefits';
import AcquireHeader from './acquire-header';
import ContentFooterWithoutRoutes from '@/layouts/shared/ContentFooterWithoutRoutes';

const InboundV2Info: React.FC<InboundV2InfoShellProps> = ({
  onPhoneClick,
  children,
  imageCampaignComponent,
  shouldShowImageCampaignComponent = false,
}) => {
  return (
    <div className="overflow-auto w-full h-full bg-display-100">
      <div className="flex mx-auto w-full md:max-w-max h-max">
        <div className={styles.formContainer}>{children}</div>
        <div className="hidden md:flex md:flex-col my-auto mr-40">
          <AcquireHeader onPhoneClick={onPhoneClick} />

          <div className={styles.imageContainer}>
            <Choose>
              <Choose.When condition={shouldShowImageCampaignComponent}>
                {imageCampaignComponent}
              </Choose.When>
              <Choose.Otherwise>
                <Image
                  width={672}
                  height={400}
                  src="site-stone/banner/adquira"
                  className="object-contain w-full h-auto"
                  alt="Produtos Stone - maquininha, cartão de crédito para empresas e pessoal e aplicativo com controle de vendas"
                />
              </Choose.Otherwise>
            </Choose>
          </div>
          <AcquireBenefits />
        </div>
      </div>
      <ContentFooterWithoutRoutes />
    </div>
  );
};

export default InboundV2Info;

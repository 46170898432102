import { isWeekend } from 'date-fns';
import isCurrentTimeBetweenChosenHours, {
  getCurrentTimeInTimezone,
} from '../services/isCurrentTimeBetweenChosenHours';

export const isOutOfAttendanceTime = () => {
  const currentDate = getCurrentTimeInTimezone('America/Sao_Paulo');

  const isItAWeekend =
    isWeekend(currentDate) && isCurrentTimeBetweenChosenHours(19, 7);

  const isTimeBetweenMidnightAndSixAm = isCurrentTimeBetweenChosenHours(0, 6);

  if (isTimeBetweenMidnightAndSixAm || isItAWeekend) {
    return true;
  }

  return false;
};

import { TInboundsMgmData } from '../types';

export async function sendInboundsMgmEntryData(
  httpClient: (endpoint: string, options?: RequestInit) => Promise<Response>,
  data: TInboundsMgmData,
) {
  const response = await httpClient('/inbounds', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`Failed to send inbound values: ${response.statusText}`);
  }

  return response;
}

'use client';

import LogoStone from '@/app/components/sections/Header/shared/LogoStone';
import { If } from '@/components/UtilitiesComponents';
import { isOutOfAttendanceTime } from '@/containers/InboundV2/helpers/isOutOfAttendanceTime';
import Link from 'next/link';

const AcquireHeader: React.FC<{
  onPhoneClick: () => void;
}> = ({ onPhoneClick }) => {
  return (
    <div className="flex flex-col lg:flex-row gap-8 lg:gap-40 px-40">
      <div className="flex lg:justify-center items-center cursor-pointer">
        <LogoStone />
      </div>
      <div>
        <p className="paragraph-16">Atendimento</p>
        <p className="font-bold text-stone-500 paragraph-20">
          {isOutOfAttendanceTime() ? 'Agendado' : 'Disponível'}
        </p>
      </div>
      <If condition={!isOutOfAttendanceTime()}>
        <div>
          <p className="paragraph-16">Adquira pelo telefone</p>
          <Link
            href="tel:30049681"
            onClick={onPhoneClick}
            className="font-bold paragraph-20"
          >
            3004 9681
          </Link>
        </div>
      </If>
    </div>
  );
};

export default AcquireHeader;

import HelpCircleIcon from '@/assets/icons/inbound/help-circle.svg';
import PercentIcon from '@/assets/icons/inbound/percent.svg';
import PosIcon from '@/assets/icons/inbound/pos.svg';
import React from 'react';

const benefits = [
  {
    phrase: 'Conhecer a sua taxa personalizada',
    icon: <PercentIcon />,
  },
  {
    phrase: 'Descobrir a solução ideal para você',
    icon: <PosIcon />,
  },
  {
    phrase: 'Tirar todas as suas dúvidas',
    icon: <HelpCircleIcon />,
  },
];

const AcquireBenefits: React.FC = () => {
  return (
    <div className="z-10 p-40 mt-[-1px] max-w-max text-white bg-display-900 rounded-br-[40px]">
      <p className="font-bold text-current paragraph-18">
        Com nosso time de vendas você vai:
      </p>
      <ul className="flex flex-col gap-12 mt-16">
        {benefits.map(({ phrase, icon }, index) => (
          <li key={index} className="flex">
            {icon}
            <p className="ml-8 font-medium text-current">{phrase}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AcquireBenefits;

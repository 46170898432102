import Image from 'next/image';

export const BlackFridayImage: React.FC = () => {
  return (
    <>
      <div className="hidden lg:flex">
        <Image
          width={672}
          height={400}
          src="stone-cms/prod/adquira_bf_9cd1a51eb2.png"
          className="object-contain w-full h-auto"
          alt="Produtos Stone - maquininha, cartão de crédito para empresas e pessoal e aplicativo com controle de vendas"
        />
      </div>

      <div className="hidden md:flex lg:hidden">
        <Image
          width={672}
          height={400}
          src="stone-cms/prod/Tablet_dfd90f3f73.png"
          className="object-contain max-w-[388px] h-auto"
          alt="Produtos Stone - maquininha, cartão de crédito para empresas e pessoal e aplicativo com controle de vendas"
        />
      </div>
    </>
  );
};

export const BlackFridayMobileImage: React.FC = () => {
  return (
    <div className="flex flex-col gap-8 justify-center items-center p-16 w-full bg-[#00231B]">
      <div className="relative">
        <Image
          width={328}
          height={126}
          src="stone-cms/prod/banner_mobile_007d6b7912.png"
          className="object-contain max-w-[328px]"
          alt="Produtos Stone - maquininha, cartão de crédito para empresas e pessoal e aplicativo com controle de vendas"
        />
      </div>
      <p className="text-[12px] leading-2 text-white">
        Confira as condições com nossos especialistas
      </p>
    </div>
  );
};

import Icon from '@/app/components/base/ui/Icon';

const Disclaimer = () => {
  return (
    <div className="my-40 md:my-32">
      <div className="flex flex-row gap-4 items-center">
        <Icon id="Copyright" size={16} />
        <p className="text-display-0 paragraph-14">
          Stone Instituição de Pagamento S.A. CNPJ 16.501.555/0001-57
        </p>
      </div>
      <p className="my-[4px] text-display-0 paragraph-14">
        Av. Doutora Ruth Cardoso, 7221, 20° andar, Pinheiros, CEP 05425-902 -
        São Paulo/SP
      </p>
      <p className="text-display-0 paragraph-14">
        Instituição de pagamento autorizada pelo Banco Central do Brasil, nas{' '}
        modalidades de credenciadora e emissora de moeda eletrônica.
      </p>
    </div>
  );
};

export default Disclaimer;

import buildUrlUtm from '@/utils/buildUrlUtm';
import { TInboundFormData } from '../types';

const TWILIO_SERVERLESS_HOST = process.env.TWILIO_SERVERLESS_HOST || '';

export const sendOneApiWhatsAppData = async (
  params: TInboundFormData,
): Promise<void> => {
  const url = `${TWILIO_SERVERLESS_HOST}/triggerStudioExecution`;
  const requestBody = {
    ...params,
    urlWithParams: buildUrlUtm(`${window.location.origin}/`),
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error(
        `Failed to create Studio execution: ${response.statusText}`,
      );
    }
  } catch (error) {
    console.error('Error creating Studio execution:', error);
    throw error;
  }
};

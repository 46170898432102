'use client';

import { CorporateContactJsonLd, NextSeo, SocialProfileJsonLd } from 'next-seo';
import Head from 'next/head';
import InboundV2Container from '@/containers/InboundV2';
import InboundV2DefaultFormsContainer from '@/containers/InboundV2/containers/InboundV2DefaultFormsContainer';

const InboundV2: React.FC = () => {
  return (
    <>
      <Head>
        <style
          dangerouslySetInnerHTML={{
            __html: `.cc-window{display:none!important}`,
          }}
        />
      </Head>{' '}
      <NextSeo
        title="Adquira sua maquininha Stone - Descubra sua taxa personalizada | Stone"
        description="Adquira agora a sua maquininha Stone pelo Chat, WhatsApp ou telefone. Saiba qual é sua taxa personalizada e todas as condições especiais que temos para você."
        canonical="https://www.stone.com.br/adquira/"
        openGraph={{
          type: 'website',
          url: 'https://www.stone.com.br/adquira/',
          title:
            'Adquira sua maquininha Stone - Descubra sua taxa personalizada | Stone',
          description:
            'Adquira agora a sua maquininha Stone pelo Chat, WhatsApp ou telefone. Saiba qual é sua taxa personalizada e todas as condições especiais que temos para você.',
          site_name: 'Stone',
          locale: 'pt_BR',
        }}
      />
      <CorporateContactJsonLd
        url="http://www.stone.com.br/"
        logo="https://www.stone.com.br/public/images/favicon.ico"
        contactPoint={[
          {
            telephone: '3004-9680',
            contactType: 'customer service',
            areaServed: 'BR',
            availableLanguage: ['Brazilian Portuguese'],
          },
          {
            telephone: '3004-9681',
            contactType: 'sales',
            areaServed: 'BR',
            availableLanguage: ['Brazilian Portuguese'],
          },
          {
            telephone: '0800 326-0506',
            contactType: 'customer service',
            areaServed: 'BR',
            contactOption: 'TollFree',
            availableLanguage: ['Brazilian Portuguese'],
          },
        ]}
      />
      <SocialProfileJsonLd
        type="Organization"
        name="Stone Pagamentos"
        url="http://www.stone.com.br/"
        sameAs={[
          'https://www.facebook.com/stonepagamentos/',
          'https://twitter.com/sejastone',
          'https://www.instagram.com/stone/',
          'https://www.youtube.com/channel/UCk4L4JykgoDkN8YeuxkNqIQ',
          'https://www.linkedin.com/company/stone-pagamentos',
          'https://pt.wikipedia.org/wiki/Stone_Pagamentos',
        ]}
      />
      <InboundV2Container>
        <InboundV2DefaultFormsContainer />
      </InboundV2Container>
    </>
  );
};

export default InboundV2;
